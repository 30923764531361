// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-car-js": () => import("./../src/pages/car.js" /* webpackChunkName: "component---src-pages-car-js" */),
  "component---src-pages-faqs-js": () => import("./../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politicas-privacidad-js": () => import("./../src/pages/politicas-privacidad.js" /* webpackChunkName: "component---src-pages-politicas-privacidad-js" */),
  "component---src-pages-prendarios-js": () => import("./../src/pages/prendarios.js" /* webpackChunkName: "component---src-pages-prendarios-js" */),
  "component---src-pages-prendas-js": () => import("./../src/pages/prendas.js" /* webpackChunkName: "component---src-pages-prendas-js" */),
  "component---src-pages-pyme-js": () => import("./../src/pages/pyme.js" /* webpackChunkName: "component---src-pages-pyme-js" */),
  "component---src-pages-simulador-js": () => import("./../src/pages/simulador.js" /* webpackChunkName: "component---src-pages-simulador-js" */),
  "component---src-pages-sms-js": () => import("./../src/pages/sms.js" /* webpackChunkName: "component---src-pages-sms-js" */),
  "component---src-pages-tyc-js": () => import("./../src/pages/tyc.js" /* webpackChunkName: "component---src-pages-tyc-js" */),
  "component---src-pages-vacantes-js": () => import("./../src/pages/vacantes.js" /* webpackChunkName: "component---src-pages-vacantes-js" */)
}

